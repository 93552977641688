.upload-btn-container {
	position: relative;
	width: 180px;
	min-height: 180px;
	padding-top: 200px;

	&.big {
		width: 220px;
		min-height: 220px;
		padding-top: 240px;

		.upload-btn-wrapper {
			width: 220px;
			height: 220px;
		}
	}

	.upload-btn-wrapper {
		position: absolute;
		z-index: 1;
		overflow: hidden;
		display: inline-block;
		top: 0px;

		width: 180px;
		height: 180px;

		border: 5px solid #ffffff;
		box-sizing: border-box;
		border-radius: 50%;

		object-fit: cover;

		&:hover {
			.upload-file-hover {
				opacity: 0.6;
			}
		}

		.avatar-image {
			width: 100%;
			height: 100%;
		}

		.upload-form-group {
			padding: 10px;
		}

		.upload-file-hover {
			position: absolute;
			top: 0px;
			z-index: 2;
			opacity: 0;

			width: 100%;
			height: 100%;

			background-color: $grey-dark;
		}
	}

	.form-file-label {
		text-align: center;
		font-size: 14px;
		line-height: 17px;
		color: $grey;
		margin-top: 10px;
		margin-right: 0;
	}

	.additional-upload-button {
		p {
			text-align: center;
			font-size: 14px;
			line-height: 17px;
			color: $grey;
		}
	}

	.upload-btn-wrapper input[type="file"] {
		font-size: 100px;
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
	}
}
