.accordion {
	&.moble-filter-accordion {
		> .accordion-item {
			border-top: 0px;
			> .accordion-header {
				.accordion-button {
					font-size: 14px;
					min-height: 40px;
					background: $grey-dark;

					box-shadow: -2px -1px 4px rgba(255, 255, 255, 0.1),
						6px 3px 10px rgba(3, 16, 33, 0.5);

					border: 0;
					border-top: 0;
					border-radius: 20px;
					color: $white;
					&.big {
						min-height: 50px;
					}
					margin-bottom: 20px;

					&:focus {
						box-shadow: -2px -1px 4px rgba(255, 255, 255, 0.1),
							6px 3px 10px rgba(3, 16, 33, 0.5);
					}
				}
			}
		}

		> .accordion-item:first-of-type .accordion-button {
			border-top-left-radius: 20px;
			border-top-right-radius: 20px;
		}
	}

	.active-collection-item {
		color: $white;
		border-radius: 20px;
		display: flex;
		align-items: center;
		padding: 6px 20px;
		background-color: $nav-dropdown-select;
		line-height: 26px;
	}
}
