.SortableTable,
.SortableTable table {
	transform: rotateX(180deg);
}

.SortableTable th {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 2;
}

.responsive-table-head {
	top: 0rem;
	@media (min-width: 1280px) {
		top: 5rem;
	}
}

div.SortableTable::-webkit-scrollbar {
	height: 20px;
	position: sticky;
}

div.SortableTable::-webkit-scrollbar-thumb {
	background: #adb5bd;
	border-radius: 100px 100px 0px 0px;
}

div.SortableTable:hover::-webkit-scrollbar-thumb:hover {
	background: #adb5bd;
}

div.SortableTable::-webkit-scrollbar-track {
	background: #ffffff2e;
	border-radius: 100px 100px 0px 0px;
}

.MarketplaceTable,
.MarketplaceTable table {
	transform: rotateX(180deg);
}

.MarketplaceTable th {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 2;
}
div.MarketplaceTable::-webkit-scrollbar {
	height: 20px;
	position: sticky;
}

div.MarketplaceTable::-webkit-scrollbar-thumb {
	background: #adb5bd;
	border-radius: 0px 0px 10px 10px;
}

div.MarketplaceTable:hover::-webkit-scrollbar-thumb:hover {
	background: #adb5bd;
}

div.MarketplaceTable::-webkit-scrollbar-track {
	background: #adb5bd;
	border-radius: 10px 10px 0px 0px;
}
