.loan-info {
	width: 100%;
	.loan-nft-image {
		object-fit: cover;
		width: 100%;
		border-radius: 20px;
	}
}

.listing-currency-label {
	padding-left: 5px;
}

.interest-rate-tooltip {
	max-height: 20px;
	padding-bottom: 3px;
}

.loan-info-card {
	width: 90%;
	height: 80%;
}
