@tailwind base;
@tailwind components;
@tailwind utilities;

@import "react-toastify/dist/ReactToastify.css";
@import "./LandingPage/LandingPage.scss";
@import "./UserProfile/UserProfile.scss";
@import "./UserProfile/UserProfileScreen.scss";
@import "./UserProfile/UserProfileSettings.scss";
@import "./NftInfo/index.scss";
@import "./LoanInfo/index.scss";
@import "./Marketplace/index.scss";
@import "./NftMetadata/index.scss";

html {
	font-size: 14px;
	overflow-y: scroll;
	height: 100%;
}

body {
	height: 100%;
}

// Customizations
@font-face {
	font-family: "Montserrat";
	src: local("Montserrat"),
		url(../font/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
	font-family: "MontserratBold";
	src: local("MontserratBold"),
		url(../font/Montserrat-Bold.ttf) format("truetype");
}

input,
button,
select,
optgroup,
textarea,
* {
	font-family: Montserrat, sans-serif;
}

.btn-check:checked + .btn-secondary:focus,
.btn-check:active + .btn-secondary:focus,
.btn-secondary:active:focus,
.btn-secondary.active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
	box-shadow: none;
}

.btn {
	min-height: 50px;
	padding: 0.375rem 1.75rem;
	margin-bottom: 10px;

	&:active,
	&:focus {
		box-shadow: none;
		border: 0;
	}
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
	font-weight: bold;
	font-family: MontserratBold, sans-serif;
}

h1 {
	font-style: normal;
	font-weight: bold;
	font-size: 36px;
	line-height: 49px;
}

h5,
.h5 {
	font-size: 20px;
	line-height: 30px;
}

span {
	font-size: 12px;
	line-height: 15px;
}

.swal2-icon span {
	font-size: unset;
	line-height: unset;
}

body,
p {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 28px;

	letter-spacing: 0.01em;

	margin-bottom: 0px;
	margin-top: 0px;
}

.text-bold {
	font-weight: bold;
	font-family: MontserratBold, sans-serif;
}

.text-grey {
	color: $white;
}

.text-gray {
	color: #aaaaaa;
}

.text-green {
	color: $primary;
}

.text-red {
	color: red;
}

.text-raster {
	font-style: italic;
	font-weight: 500;
	font-size: 14px;
	color: $grey-light;
}

.text-16 {
	font-size: 16px;
}

.text-14 {
	font-size: 14px;
}

.text-12 {
	font-size: 12px;
}

.line-height-19 {
	line-height: 19px;
}

.btn-secondary {
	min-height: 50px;

	&.small {
		min-height: 40px;
	}

	box-shadow: -3px -1px 5px rgba(255, 255, 255, 0.17),
		0px 5px 7px rgba(0, 6, 13, 0.45);
	border-radius: 25px;
	border: 0;
}

.btn-outline-primary {
	&.small {
		max-height: 40px;
		min-height: 20px;
	}
}

.btn-primary {
	font-size: 14px;
	min-height: 40px;

	background-color: $primary;

	box-shadow: -3px -1px 5px rgba(255, 255, 255, 0.17),
		0px 5px 7px rgba(0, 6, 13, 0.45);
	border-radius: 25px;
	border: 0;

	&.big {
		min-height: 50px;
	}

	&.small {
		max-height: 40px;
		min-height: 20px;
	}
}

.btn-secondary {
	font-size: 14px;
	min-height: 40px;
	background: $grey-dark;
	/* Neu button */

	box-shadow: -2px -1px 4px rgba(255, 255, 255, 0.1),
		6px 3px 10px rgba(3, 16, 33, 0.5);

	border: 0;
	color: $white;

	&.big {
		min-height: 50px;
	}
}

.btn-colorful {
	background-image: url("../media/landingPage/colorfulBkg.png");
	background-size: cover;
	background-position: center center;
}

.glow {
	box-shadow: 0px 50px 48px -30px rgba(182, 255, 124, 0.2),
		0px 50px 48px -30px rgba(52, 236, 225, 0.2);
}

.btn-subscribe {
	position: relative;
}

.twitterLogo {
	margin-right: 8px;
}

.subscribeFooter {
	margin-top: -10px;
}

.padding-20 {
	padding: 20px;
}

.padding-10 {
	padding: 10px;
}

.marketplaceTable {
	padding: 0px;
	margin-top: 5px;
}

.marketplaceTable td {
	padding: 0px;
}

.stretched-link {
	margin-top: 100px;
}

.welcome {
	color: $primary;
}

.strike {
	text-decoration: line-through;
}

.avoid-clicks {
	pointer-events: none;
}

.strike {
	text-decoration: line-through;
}

.coming-soon {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	border: none;
	margin: 0;
	padding: 0;
	overflow: hidden;
	z-index: 999999;
}

.requirementSpacingTopShot {
	li {
		margin-top: 0.375rem;
		margin-bottom: 0.375rem;
	}
}

.shepherd-cancel-icon {
	span {
		font-size: 1.5em;
	}
}

.bg-white-important {
	background: white !important;
}

.white-box-shadow:hover {
	box-shadow: 0px 0px 12px 0px white !important;
}

.hover-filter-text:hover {
	filter: drop-shadow(0px 0px 6px #04e5a3);
}

.accordion-button {
	margin: 0px;
	padding: 0px;
}

.accordion-accounts {
	.accordion-body {
		padding-top: 1.5rem !important;
	}
}

/* Small Devices, Tablets */
@media only screen and (max-width: 992px) {
	.collection-bg {
		background-image: linear-gradient(
			263.52deg,
			#03102159 13.55%,
			#031021b3 38.62%,
			#031021f5 67.84%,
			#031021 100%
		);
	}
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 992px) {
	.collection-bg {
		background-image: linear-gradient(
			263.52deg,
			#0f0f0f00 13.55%,
			#0f0f0f80 38.62%,
			#031021 67.84%,
			#031021 100%
		);
	}
}

.swiper-button-next,
.swiper-button-prev {
	color: white !important;
	top: 75% !important;
	padding-left: 2rem;
	padding-right: 2rem;
}

.swiper-pagination-bullet {
	background-color: slategray !important;
}

.swiper-pagination-bullet-active {
	background-color: white !important;
}

.responsive-sticky-top {
	@media (min-width: 1024px) {
		top: 5.5rem;
		position: sticky;
	}

	@media (min-width: 1280px) {
		top: 6.5rem;
		position: sticky;
	}
}

.responsive-mobile {
	top: 8.5rem;
	position: sticky;
	z-index: 10;

	@media (min-width: 768px) {
		top: 5rem;
		position: sticky;
		z-index: 19;
	}

	@media (min-width: 1024px) {
		top: 0rem;
		position: initial;
		z-index: 0;
	}
}

.container {
	@media (max-width: 766px) {
		max-width: 100%;
		padding-right: 14px;
		padding-left: 14px;
	}

	@media (min-width: 766px) {
		max-width: 100%;
		padding-right: 32px;
		padding-left: 32px;
	}

	@media (min-width: 896px) {
		max-width: 100%;
		padding-right: 32px;
		padding-left: 32px;
	}

	@media (min-width: 1024px) {
		max-width: 100%;
		padding-right: 32px;
		padding-left: 32px;
	}

	@media (min-width: 1280px) {
		max-width: 100%;
		padding-right: 32px;
		padding-left: 32px;
	}

	@media (min-width: 1440px) {
		max-width: 100%;
		padding-right: 32px;
		padding-left: 32px;
	}
}

.custom-nav-item:hover {
	color: black !important;
}

.inherit-width {
	left: 50%;
	transform: translateX(-50%);
}

.hover-button:hover {
	box-shadow: 0px 0px 12px #00ffb4 !important;
}

.hover-button-text:hover {
	text-shadow: 0px 0px 12px #00ffb4 !important;
}

.hover-button-white:hover {
	box-shadow: 0px 0px 12px #ffffff !important;
}

.hover-white-button:hover {
	box-shadow: 0px 0px 12px #ffffff !important;
}

.hover-warning-button:hover {
	box-shadow: 0px 0px 12px #ff6e25;
}

.hover-button-red:hover {
	box-shadow: 0px 0px 12px #ff2f2f !important;
}

.hover-button-orange-text:hover {
	text-shadow: 0px 0px 12px #ff692e !important;
}

.hover-button-purple-text:hover {
	text-shadow: 0px 0px 12px #6d6eff !important;
}

.hover-button-white:hover {
	background: #ffffff;
	box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.25);
	color: #031021;
}

.hover-glow:hover {
	box-shadow: 0px 0px 12px 2px;
}

.hover-nav-item:hover {
	text-shadow: 0 0 5px #6d6b6b, 0 0 10px #6d6b6b, 0 0 5px #6d6b6b,
		0 0 10px #6d6b6b, 0 0 5px #6d6b6b, 0 0 10px #6d6b6b, 0 0 10px #6d6b6b;
}

.hover-nav-item-red:hover {
	text-shadow: 0 0 5px #9f1f1f, 0 0 10px #9f1f1f, 0 0 5px #9f1f1f,
		0 0 10px #9f1f1f, 0 0 5px #9f1f1f, 0 0 10px #9f1f1f, 0 0 10px #9f1f1f;
}

.font-montserrat {
	font-family: "Montserrat";
}

.font-montserrat-bold {
	font-family: "Montserrat", sans-serif;
}

.cursor-pointer {
	cursor: pointer !important;
}

.custom-flowty-bg {
	background: #031021 url("../media/background-main.png") fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 100vh;
}

//Snow

.snowflake {
	--size: 2vw;
	width: var(--size);
	height: var(--size);
	background-image: url("../media/snow/Snowflake.svg");
	position: fixed;
	top: -5vh;
}

@media (min-width: 1024px) {
	.filters-max-height {
		max-height: calc(100vh - 200px);
	}
}

@keyframes snowfall {
	0% {
		transform: translate3d(var(--left-ini), 0, 0);
	}

	100% {
		transform: translate3d(var(--left-end), 100vh, 0);
	}
}

@for $i from 1 through 50 {
	.snowflake:nth-child(#{$i}) {
		--size: #{random(5) * 0.2}vw;
		--left-ini: #{random(20) - 10}vw;
		--left-end: #{random(20) - 10}vw;
		left: #{random(100)}vw;
		animation: snowfall #{5 + random(10)}s linear infinite;
		animation-delay: -#{random(10)}s;
	}
}

/* added small blur every 6 snowflakes*/
.snowflake:nth-child(6n) {
	filter: blur(1px);
}

/* The Overlay (background) */
.overlay {
	/* Height & width depends on how you want to reveal the overlay (see JS below) */
	height: 100%;
	position: fixed;
	backdrop-filter: blur(80px);
	/* Stay in place */
	z-index: 100;
	/* Sit on top */
	left: 0;
	top: 0;
	overflow-x: hidden;
	/* Disable horizontal scroll */
	transition: 0.5s;
	/* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

/* Position the content inside the overlay */
.overlay-content {
	position: relative;
	width: 100%;
	/* 100% width */
}

.w-full-under {
	div {
		width: 100%;
	}

	button {
		width: 100%;
	}
}

.border-tail {
	border-width: 1px;
}

.icon-negative-margin {
	margin-left: -0.5rem !important;
}

.border-modal {
	border-color: #445669;
}

.react-pdf__Page__annotations {
	display: none;
}

.react-pdf__Page__textContent {
	display: none;
}

.tab-window-padding {
	padding-left: 0.5rem;
	padding-right: 0.5rem;

	@media (min-width: 768px) {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}
}

.animate-all {
	transition: 0.5s;
}

.nav-bg {
	background: linear-gradient(
		180deg,
		#031021 21.88%,
		rgba(3, 16, 33, 3.01) 50%,
		rgba(255, 255, 255, 0.01) 100%
	);
	backdrop-filter: blur(50px);
	border-bottom: 0.5px solid transparent;
	border-image: linear-gradient(
		90deg,
		rgba(76, 91, 105, 0) 0%,
		#4c5b69 49.76%,
		rgba(76, 91, 105, 0) 100%
	);
	border-image-width: 0 0 1 0;
	border-image-slice: 1;
}

.bg-blur {
	backdrop-filter: blur(50px);
	-webkit-backdrop-filter: blur(50px);
}

.bg-blur-navbar {
	backdrop-filter: blur(50px);
	-webkit-backdrop-filter: blur(50px);
	background: #04070b20;
}

.bg-blur-light {
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
}

.bg-blur-51 {
	backdrop-filter: blur(51px);
	-webkit-backdrop-filter: blur(51px);
}

.inherit-width {
	width: inherit;
}

.markdown-list {
	a {
		color: #04e5a3;
		text-decoration: underline;
	}

	li {
		list-style: disc;
		margin-left: 1.5em;
	}

	ul {
		padding: 0 !important;
	}
}

.markdown-messages {
	a {
		text-decoration: underline;
	}
}

@media (max-width: 768px) {
	.mobile-overflow-x-hidden {
		overflow-x: hidden;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.fade-in {
	animation: fadeIn 500ms;
}

.nftCard {
	background: linear-gradient(
		153deg,
		rgba(255, 255, 255, 0.12) 0%,
		rgba(255, 255, 255, 0.06) 100%
	);
	backdrop-filter: blur(21px);
	border: 1.2px solid transparent;
}

.nftCard:hover {
	background: linear-gradient(
		153deg,
		rgba(255, 255, 255, 0.2) 0%,
		rgba(255, 255, 255, 0) 100%
	);
	border: 1.2px solid rgba(255, 255, 255, 0.467);
	backdrop-filter: blur(0px);
	.text-xs {
		color: white !important;
	}

	.font-montserrat {
		color: white !important;
	}

	p {
		color: white !important;
	}
}

.bulkPopup {
	background: linear-gradient(
		153deg,
		rgba(255, 255, 255, 0.12) 50%,
		rgba(255, 255, 255, 0.06) 100%
	);
	backdrop-filter: blur(41px);
}

.accordion-button:not(.collapsed)::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23f2f2f3%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed) {
	box-shadow: none;
}

/* width */
::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #525252;
	border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #898989;
}

::-webkit-scrollbar-corner {
	background: transparent;
}

.bulk-all-btn {
	--shine-deg: 75deg;
	box-shadow: 0 25px 50px -12px rgba(33, 33, 33, 0.25);

	background-repeat: no-repeat;
	background-position: -100% 0, 0 0;

	background-image: linear-gradient(
		var(--shine-deg),
		transparent 20%,
		transparent 40%,
		rgba(255, 255, 255, 0.2) 50%,
		rgba(224, 224, 224, 0.2) 65%,
		transparent 80%,
		transparent 100%
	);

	background-size: 250% 250%, 100% 100%;
	transition: background-position 0s ease;
}

.bulk-all-btn-active {
	background-position: 200% 0, 0 200%;
	transition-duration: 3s;
}

.eventTypeCard {
	border-radius: 8px;
	border: 1px solid var(--neutral-300, #495057);
	background: linear-gradient(
		153deg,
		rgba(255, 255, 255, 0.12) 0%,
		rgba(255, 255, 255, 0.06) 100%
	);
	backdrop-filter: blur(21px);
}

.collectionCard {
	border-radius: 8px;
	border: 1px solid var(--neutral-300, #495057);
	background: linear-gradient(
		153deg,
		rgba(255, 255, 255, 0.12) 0%,
		rgba(255, 255, 255, 0.06) 100%
	);
}

.collectionCard:hover {
	border-radius: 8px;
	border: 1px solid var(--neutral-400, #6c757d);
	background: linear-gradient(
		153deg,
		rgba(255, 255, 255, 0.2) 0%,
		rgba(255, 255, 255, 0) 100%
	);
	box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
	backdrop-filter: blur(21px);
}

/* DS Flowty Classes */

.hover-danger-button:hover {
	box-shadow: 0px 0px 12px #ff2f2f;
}

.bg-profile {
	background-image: url("../media/userProfile/background-profile.svg");
}

.bg-landing {
	background-image: url("https://storage.googleapis.com/flowty-images/lp-cards.svg");
	background-position: right center;
	background-size: cover;
	background-repeat: no-repeat;
}

@media (min-width: 993px) {
	.bg-landing {
		background-size: 80%;
	}
}

.double-height {
	height: 200vh;
}

.nav-large {
	@media (max-width: 1024px) {
		display: none;
	}
}

.nav-medium {
	display: none;
	min-height: 75px;
	z-index: 40;

	@media (max-width: 1024px) {
		display: grid;

		@media (min-width: 574px) {
			margin: auto;
		}

		@media (min-width: 640px) {
			margin: auto;
		}

		@media (min-width: 747px) {
			margin: auto;
		}

		@media (min-width: 896px) {
			margin: auto;
		}

		& > * {
			grid-column: 1;
			grid-row: 1;
		}
	}
}

.avatar-container {
	width: 48px;
	height: 48px;

	@media (max-width: 768px) {
		width: 2.5rem;
		height: 2.5rem;
	}

	object-fit: cover;
	border-radius: 5px;
	overflow: hidden;

	img {
		width: 100%;
		height: 100%;
	}
}

.flowty-searchbar:focus {
	outline: 2px solid transparent;
	outline-offset: 2px;
	--tw-ring-inset: var(--tw-empty);
	--tw-ring-offset-width: 0px;
	--tw-ring-offset-color: #fff;
	--tw-ring-color: #04e5a3 !important;
	--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
		var(--tw-ring-offset-width) var(--tw-ring-offset-color);
	--tw-ring-shadow: var(--tw-ring-inset) 0 0 0
		calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
		var(--tw-shadow);
	border-color: #04e5a3 !important;
}

.flowty-checkbox {
	border: 1px solid #dee2e6 !important;
	background-color: transparent !important;
}
.flowty-checkbox:focus {
	outline: none !important;
	outline-offset: 0px;
	--tw-ring-inset: var(--tw-empty);
	--tw-ring-offset-width: 0px;
	--tw-ring-offset-color: transparent !important;
	--tw-ring-color: #04e5a3 !important;
	--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
		var(--tw-ring-offset-width) var(--tw-ring-offset-color);
	--tw-ring-shadow: var(--tw-ring-inset) 0 0 0
		calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
		var(--tw-shadow);
	color: #00ffb4 !important;
	background-color: #00ffb4 !important;
	border: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.flowty-nft-card {
	background: linear-gradient(
		153deg,
		rgba(255, 255, 255, 0.12) 0%,
		rgba(255, 255, 255, 0.06) 100%
	);
}

.buyListingBannerGradient {
	background: linear-gradient(
		90deg,
		#04e5a3 0.82%,
		rgba(94, 43, 151, 0) 99.88%
	);
}

.loanListingBannerGradient {
	background: linear-gradient(
		90deg,
		#5152ff 0.82%,
		rgba(94, 43, 151, 0) 99.88%
	);
}

.rentalListingBannerGradient {
	background: linear-gradient(
		90deg,
		#ff692e 0.82%,
		rgba(94, 43, 151, 0) 99.88%
	);
}

.border-gradient {
	border-style: solid;
	border-width: 0px 0px 0.5px 0px;
	border-image: linear-gradient(
			to right,
			rgba(0, 0, 0, 0),
			#4c5b69,
			rgba(0, 0, 0, 0)
		)
		1;
}

.modal-height {
	@media screen and (max-height: 900px) and (min-width: 1200px) {
		transform: scale(0.8);
	}
}

.saleTagPadding {
	padding: 9px 15px 9px 7.5em;
}

@media screen and (min-width: 1200px) {
	.saleTagPadding {
		padding: 9px 15px 9px 41px;
	}
}

.flowty-dropdown {
	border-radius: 6px;
	background: var(--grey-green-20, rgba(96, 110, 125, 0.2));
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
		0px 10px 15px -3px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(50px);
}

.recent-text {
	font-family: "MontserratBold", sans-serif;
	font-style: normal;
	font-weight: 800;
	line-height: normal;
}

/* Real outline for modern browsers */
@supports (
	(text-stroke: 0.87px #d3d3d3) or (-webkit-text-stroke: 0.87px #d3d3d3)
) {
	.recent-text {
		color: transparent;
		-webkit-text-stroke: 0.87px #d3d3d3;
		text-shadow: none;
	}
}

.customStepsBg:hover {
	border: 1px solid #343a40;
	background: linear-gradient(
		153deg,
		rgba(255, 255, 255, 0.07) 0%,
		rgba(255, 255, 255, 0.04) 100%
	);
}

.customPaddingVideo {
	@media (min-width: 768px) {
		left: -2.5em;
	}
	@media (min-width: 900px) {
		left: -2.5em;
	}
	@media (min-width: 1020px) {
		left: -2.5em;
	}
	@media (min-width: 1200px) {
		left: -2.5em;
	}
	@media (min-width: 1440px) {
		left: -2.5em;
	}
	@media (min-width: 1520px) {
		left: -2.5em;
	}
	@media (min-width: 1650px) {
		left: -2.5em;
	}
}

.customPaddingAttr {
	@media (min-width: 768px) {
		margin-left: 9em;
	}
	@media (min-width: 1000px) {
		margin-left: 0em;
	}
	@media (min-width: 1430px) {
		margin-left: 9em;
	}
	@media (min-width: 1800px) {
		margin-left: 0em;
	}
}

.custom-collection-border {
	border-left: 6px solid rgba(52, 58, 64, 0.64);
}

.top-sales-spacing {
	@media screen and (min-width: 1440px) and (max-width: 1770px) {
		width: 240px;
		column-gap: 16px;
	}
	width: 340px;
	column-gap: 24px;
}

.custom-link-top {
	@media screen and (min-width: 1440px) and (max-width: 1770px) {
		padding: 4px 8px;
		span {
			font-size: 10px;
		}
	}
	padding: 12px 24px;
	span {
		font-size: 14px;
	}
}

.top-sales-image {
	height: 67px;
	width: 67px;
}

.top-sales-title {
	@media screen and (min-width: 1440px) and (max-width: 1770px) {
		font-size: 12px;
	}
	font-size: 16px;
}

.top-sales-subtitle {
	@media screen and (min-width: 1440px) and (max-width: 1770px) {
		font-size: 12px;
	}
	font-size: 14px;
}

.top-sales-describer {
	@media screen and (min-width: 1440px) and (max-width: 1770px) {
		font-size: 12px;
	}
	font-size: 14px;
}

.top-sales-value {
	@media screen and (min-width: 1440px) and (max-width: 1770px) {
		font-size: 14px;
	}
	font-size: 18px;
}

.customCollectionStyle {
	border: 0.6px solid #343a40;
	background: linear-gradient(
		153deg,
		rgba(255, 255, 255, 0.12) 0%,
		rgba(255, 255, 255, 0.06) 100%
	);
	backdrop-filter: blur(12.6px);

	&:hover {
		border: 1px solid #5e5e5e;
		background: linear-gradient(
			153deg,
			rgba(255, 255, 255, 0.16) 0%,
			rgba(255, 255, 255, 0.08) 100%
		);
		backdrop-filter: blur(12.6px);
	}
}

.custom-collection-bg {
	background: rgba(255, 255, 255, 0.02);
}

.custom-truncate {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.searchResultsBackground {
	background: rgba(96, 110, 125, 0.2);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
		0px 10px 15px -3px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(75px);
}

.custom-attributes-border {
	@media screen and (min-width: 768px) {
		border: 0.28px solid rgba(255, 255, 255, 0.4);
	}
}

.custom-video-border {
	@media screen and (min-width: 768px) {
		border: 0.28px solid rgba(255, 255, 255, 0.4);
	}
}

.custom-shadow-feature {
	text-shadow: 0px 0px 1.982px #fff;
	@media screen and (min-width: 768px) {
		text-shadow: 0px 0px 4px #fff;
	}
}

.custom-shadow-steps {
	text-shadow: 0px 0px 2.934px #fff;
	@media screen and (min-width: 768px) {
		text-shadow: 0px 0px 4px #fff;
	}
}

.gradient-bg-search {
	background: linear-gradient(
		90deg,
		rgba(76, 91, 105, 0) 0%,
		#4c5b69 49.76%,
		rgba(76, 91, 105, 0) 100%
	);
}

.custom-padding-dapperBar {
	@media screen and (min-width: 1024px) {
		// calculated the height of the navbar by using offsetHeight
		margin-top: 76px;
	}
}

.custom-background-filters {
	--tw-bg-opacity: 1;
	background-color: rgb(4 7 11 / var(--tw-bg-opacity));
	margin-left: -32px;
	margin-right: -32px;

	@media (min-width: 1024px) {
		backdrop-filter: blur(50px);
		-webkit-backdrop-filter: blur(50px);
		background: rgba(4, 7, 11, 0.1254901961);
	}
}

.custom-bg-collections-landing {
	background: linear-gradient(
		153deg,
		rgba(255, 255, 255, 0.12) 0%,
		rgba(255, 255, 255, 0.06) 100%
	);
}

.custom-bg-collections-landing:hover {
	background: linear-gradient(
		153deg,
		rgba(255, 255, 255, 0.26) 0%,
		rgba(255, 255, 255, 0.13) 100%
	);
}

.custom-opacity-dapperbar {
	--tw-bg-opacity: 0.08;
}

/* End DS Flowty Class */

.click-effect:active {
	background-color: #495057;
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

.spinner {
	animation: spin 1s linear infinite;
}

.group:hover .icon-hover-effect-dropdown {
	color: #adb5bd;
}

.tweet-media_rounded__mrLU- {
	max-height: 200px;
	overflow-y: auto;
}

.quoted-tweet-container_root__PJSCP {
	max-height: 150px;
	overflow-y: auto;
}

.tweet-link_root__NxGGl {
	font-size: 12px;
	line-height: 15px;
}

.tweet-container_root__Sxgvo {
	max-width: 300px;
}

.creator-hub-wrapper {
	height: calc(100vh - 80px);

	@media (max-width: 768px) {
		height: calc(100svh - 80px);
	}
}

.creator-hub-nft-list-wrapper {
	grid-template-columns: repeat(3, 1fr);
}

@media (min-width: 1240px) {
	.creator-hub-nft-list-wrapper {
		grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
		grid-template-rows: repeat(2, 1fr);
	}
}

.creator-hub-title-gradient {
	background: var(--Linear-Text, linear-gradient(90deg, #fff 0%, #aeaeae 100%));
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

/* Date Picker*/
#drop-form .react-datepicker {
	background-color: #04070b !important;
	position: relative !important;
	color: #fff !important;
	font-family: Montserrat, sans-serif !important;
	width: fit-content !important;
	border: none !important;
}

#drop-form .react-datepicker__header {
	border-radius: 8px 8px 0 0 !important;
	background: #04070b !important;
	color: #fff !important;
	border-bottom: none !important;
}

#drop-form .react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
	background-color: white !important;
	border-radius: 1.8125rem !important;
	color: black !important;
}

#drop-form .react-datepicker__day--keyboard-selected {
	background-color: white !important;
	border-radius: 1.8125rem !important;
	color: black !important;
}

#drop-form .react-datepicker-popper {
	border-width: 1px !important;
	border-color: #6c757d !important;
	border-radius: 0.5rem !important;
	width: 100%;
	transform: none !important;
	margin-top: 45px !important;
	background-color: #04070b !important;
}
#drop-form .react-datepicker__day {
	font-size: 1rem;
}

#drop-form .react-datepicker__day--today {
	outline: white !important;
	border-width: 1px !important;
	border-radius: 1.8125rem !important;
	color: white !important;
	background-color: black !important;
}

#drop-form .react-datepicker__day--range-start {
	background-color: white !important;
	border-radius: 1.8125rem !important;
	color: black !important;
}

#drop-form .react-datepicker__day--in-range {
	background-color: white !important;
	border-radius: 1.8125rem !important;
	color: black !important;
}

#drop-form .react-datepicker__day--range-end {
	background-color: white !important;
	border-radius: 50% !important;
	color: black !important;
}

#drop-form .react-datepicker__day:hover {
	background-color: #4a5568 !important;
	outline: none;
}

#drop-form .react-datepicker__current-month {
	color: #fff !important;
	font-size: 0.875rem !important;
	font-weight: 500 !important;
	line-height: 0.875rem !important;
}
#drop-form .react-datepicker__month-container {
	box-shadow: 2px 16px 19px 0px rgba(0, 0, 0, 0.09) !important;
	margin-bottom: 17px !important;
}

#drop-form .react-datepicker__input-container input:focus {
	outline: none; /* Remove the focus outline */
}

#drop-form .react-datepicker__day--in-selecting-range {
	background-color: black;
}

#drop-form .react-datepicker__day,
.react-datepicker__time-name {
	color: white;
	display: inline-block;
	width: 1.875rem !important;
	margin: 0.35rem !important;
}

#drop-form .react-datepicker__day-name {
	color: #b5bec6 !important;
	width: 2.24rem !important;
	text-transform: uppercase;
	padding-top: 1.38rem !important;
}

#drop-form .react-datepicker__day--outside-month {
	color: #495057 !important;
}

#drop-form .react-datepicker__navigation {
	top: 0.25rem !important;
	align-items: normal !important;
}
#drop-form .react-datepicker__day--disabled {
	color: rgba(255, 255, 255, 0.5) !important;
	background-color: #04070b !important;
	border-radius: none !important;
}
#drop-form .react-datepicker__day--disabled:hover {
	background-color: #04070b !important;
	border-radius: none !important;
}

#drop-form .custom-disabled-day {
	color: #212529 !important;
	background-color: #f0f0f0 !important;
	border-radius: 1.8125rem;
	opacity: 0.3;
}

.react-datepicker__day--disabled.custom-disabled-day {
	color: #ccc !important;
}

#drop-form .react-datepicker__day--disabled.custom-disabled-day:hover {
	background-color: #f0f0f0 !important;
	border-radius: 1.8125rem;
	opacity: 0.3;
	outline: none;
}

@media (max-width: 2000px) {
	#drop-form .react-datepicker-popper {
		transform: none !important;
		border-width: 1px !important;
		border-color: #6c757d !important;
		border-radius: 0.5rem !important;
		background-color: #04070b !important;
		font-size: 9.993px !important;
		margin-top: 35px !important;
	}
}

@media (max-width: 768px) {
	#drop-form .react-datepicker__header {
		padding: 5px !important;
	}

	#drop-form .react-datepicker-popper {
		transform: none !important;
		border-width: 1px !important;
		border-color: #6c757d !important;
		border-radius: 0.5rem !important;
		width: 100%;
		background-color: #04070b !important;
	}

	#drop-form .react-datepicker__day {
		width: 1.25rem !important;
		height: 1.25rem !important;
		line-height: 1.25rem !important;
		font-size: 12px !important;
	}

	#drop-form .react-datepicker__day-name {
		padding-top: 1rem !important;
		font-size: 12px !important;
		width: 1.5rem !important;
	}

	#drop-form .react-datepicker__current-month {
		color: #fff !important;
		font-size: 0.875rem !important;
		font-weight: 500 !important;
		line-height: 0.875rem !important;
		padding-top: 0.25rem !important;
	}

	#drop-form .react-datepicker__month-container {
		box-shadow: none !important;
		left: 0px !important;
	}

	#drop-form .react-datepicker__input-container input {
		width: 100% !important;
	}

	#drop-form .react-datepicker__navigation {
		align-items: flex-start !important;
	}
}

.actionNftCard {
	border-radius: 6.339px;
	border: 0.792px solid var(--Neutral-300, #495057);
	background: linear-gradient(
		153deg,
		rgba(255, 255, 255, 0.12) 0%,
		rgba(255, 255, 255, 0.06) 100%
	);
}

.actionNftCard:hover {
	border: 0.792px solid rgba(255, 255, 255, 0.467);
}
