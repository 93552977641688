.container {
	.myswiper {
		.swiperslide {
			-webkit-transform: translateZ(0);
			width: 100%;
			height: 100%;
		}

		.shadow-inner {
			box-shadow: none;
			--tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
				0 4px 6px -4px rgb(0 0 0 / 0.1);
			--tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
				0 4px 6px -4px var(--tw-shadow-color);
			box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
				var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
			border-radius: 20px;
			background: #64748b6b;
			border: 1px solid white;
		}
	}
}
