ul.drop-down::-webkit-scrollbar {
	width: 5px;
}

ul.drop-down::-webkit-scrollbar-thumb {
	background: #3b82f6;
	border-radius: 0px 8px 8px 0px;
}

ul.drop-down:hover::-webkit-scrollbar-thumb:hover {
	background: #6366f1;
}

ul.drop-down::-webkit-scrollbar-track {
	background: #293646;
	border-radius: 0px 8px 8px 0px;
	padding-right: 4px;
}
