.collection-item-image {
	width: 26px;
	height: 26px;

	object-fit: cover;
	border-radius: 13px;
	overflow: hidden;

	img {
		width: 100%;
		height: 100%;
		vertical-align: 0;
	}
}

.btn-cross {
	align-self: end;
}
