.user-data-block {
	margin-top: 80px;

	.jumbotron {
		padding-left: 80px;
		padding-right: 80px;
	}
	.btn-skip {
		position: relative;
		top: -60px;
		right: -60px;
	}
}

.user-profile-data-container {
	position: relative;
	border-radius: 20px;
	padding: 20px;
	padding-top: 80px;

	margin-top: 140px;

	.wallet-connected-upload-btn {
		top: -100px;
		margin: auto;
		position: absolute;
	}

	.form-label {
		margin-bottom: 0px;
		margin-right: 15px;
		color: $grey-light;

		font-size: 17px;
		line-height: 20px;
	}

	// custom form inputs

	.form-control {
		background: transparent;

		box-shadow: none;
		border-radius: 0;

		&:focus {
			background: transparent;
		}
	}

	input {
		text-align: center;
		height: 50px;
		&.form-control {
			padding-left: 0px;
		}

		&::-webkit-input-placeholder {
			color: $grey-light;
		}
		&:-moz-placeholder {
			color: $grey-light;
		}
		&::-moz-placeholder {
			color: $grey-light;
		}
		&:-ms-input-placeholder {
			color: $grey-light;
		}
		&::-ms-input-placeholder {
			color: $grey-light;
		}
		&::placeholder {
			color: $grey-light;
		}
	}

	.form-group {
		position: relative;
	}

	// .placeholderIcon {
	// 	position: absolute;
	// 	bottom: 16px;
	// 	left: 15px;
	// }

	.username-form-field {
		font-family: MontserratBold, sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 49px;
		color: $white;

		&::-webkit-input-placeholder {
			color: $white;
		}
		&:-moz-placeholder {
			color: $white;
		}
		&::-moz-placeholder {
			color: $white;
		}
		&:-ms-input-placeholder {
			color: $white;
		}
		&::-ms-input-placeholder {
			color: $white;
		}
		&::placeholder {
			color: $white;
		}
	}

	.btn-copy {
		padding: 5px;
		margin-left: 5px;
	}
}
