.accordion-collection-list {
	max-height: 400px;
	overflow-y: scroll;

	button {
		&:hover {
			background-color: $nav-dropdown-hover;
			color: $white;
		}
	}
}

.accordion-sort-list {
	max-height: 400px;
	overflow-y: scroll;

	button {
		p {
			color: $grey-light;
		}

		&.active {
			p {
				color: $primary;
				&::after {
					display: inline-block;
					margin-left: 16px;
					width: 10px;
					height: 10px;
					content: "";

					background-image: url("../../../assets/media/marketplacePage/sortActiveIcon.svg");
					background-repeat: no-repeat;
				}
			}
		}
	}
}
