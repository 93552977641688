.hide-scroll::-webkit-scrollbar

/* Hide scrollbar for Chrome, Safari and Opera */ {
	display: none;
}

.hide-scroll {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.facet-search-scrollbar::-webkit-scrollbar {
	width: 10px;
	margin: 0px;
}

.facet-search-scrollbar::-webkit-scrollbar-thumb {
	background: #6a6e74;
}

.facet-search-scrollbar:hover::-webkit-scrollbar-thumb:hover {
	background: #767677;
}

.facet-search-scrollbar::-webkit-scrollbar-track {
	background: #2f3c4b;
}

/* width */
.styled-scroll-wallet::-webkit-scrollbar {
	height: 8px;
}

/* Track */
.styled-scroll-wallet::-webkit-scrollbar-track {
	background: rgba(114, 115, 115, 255);
	border-radius: 10px 10px 10px 10px;
}

/* Handle */
.styled-scroll-wallet::-webkit-scrollbar-thumb {
	background: rgba(192, 193, 192, 255);
	border-radius: 10px 10px 10px 10px;
}

/* Handle on hover */
.styled-scroll-wallet::-webkit-scrollbar-thumb:hover {
	background: rgba(192, 193, 192, 0.8);
}

.styled-scroll::-webkit-scrollbar {
	width: 5px;
	margin: 0px;
}

.styled-scroll::-webkit-scrollbar-thumb {
	background: rgba(124, 124, 124, 0.6);
	border-radius: 10px 10px 10px 0px;
}

.styled-scroll:hover::-webkit-scrollbar-thumb:hover {
	background: rgba(124, 124, 124, 0.9);
}

.styled-scroll::-webkit-scrollbar-track {
	background: rgba(124, 124, 124, 0.25);
	border-radius: 10px 10px 10px 0px;
}
