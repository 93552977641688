div.properties-container::-webkit-scrollbar {
	width: 5px;
	position: sticky;
}

div.properties-container::-webkit-scrollbar-thumb {
	background: #3b82f6;
	border-radius: 0px 10px 10px 0px;
}

div.properties-container:hover::-webkit-scrollbar-thumb:hover {
	background: #6366f1;
}
div.properties-container::-webkit-scrollbar-track {
	background: #293646;
	border-radius: 0px 10px 10px 0px;
}
