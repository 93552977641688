.nft-listing {
	padding: 40px;
	border-radius: 20px;

	background-image: url("../../media/landingPage/colorfulBkg.png");
	background-size: cover;
	background-position: center center;

	border-radius: 20px;
}

.nft-card {
	width: 250px;
	border-radius: 18px;
	overflow: hidden;

	.nft-card-image {
		position: relative;
		width: 100%;
		height: 250px;
		object-fit: cover;

		&.small {
			height: 76px;
			width: 76px;
			border-radius: 10px;
		}
	}

	.nft-card-countdown-container {
		position: absolute;
		top: 15px;
		left: 15px;
		right: 15px;
	}

	&.placeholder {
		height: 370px;
	}
}

.metaData {
	line-height: 1.5em;
	height: 12%;
}

.nft-info-metadata-text {
	line-height: 1.5;
}

.card-term-text {
	font-size: 10px;
}

.nft-card-fixed-height {
	height: 21px;
}

.storefront-indicator-gradient {
	background: linear-gradient(
		90.31deg,
		#04e5a3 0.82%,
		rgba(94, 43, 151, 0) 99.88%
	);
}

.loan-indicator-gradient {
	background: linear-gradient(
		90.31deg,
		#2f2fa5 0.82%,
		rgba(94, 43, 151, 0) 99.88%
	);
}
.rental-indicator-gradient {
	background: linear-gradient(
		90.31deg,
		#973c19 0.82%,
		rgba(94, 43, 151, 0) 99.88%
	);
}

.nft-card-shadow:hover {
	box-shadow: 0px 0px 4px 0px #79797940;
}
