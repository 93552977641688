.mobile-action-button {
	position: fixed;
	z-index: 998;
	bottom: 0px;
	left: 0;
	width: 100%;
	height: 100px;
	background: linear-gradient(
		180deg,
		rgba(3, 16, 33, 0) 0%,
		rgba(3, 16, 33, 0.38) 30.67%,
		#031021 100%
	);
}
