.nft-expiration {
	.nft-expiration-left,
	.nft-expiration-right {
	}

	.nft-expiration-left {
		border-bottom-left-radius: 20px;
		border-top-left-radius: 20px;
		background-color: rgba(243, 245, 249, 0.2);
	}

	.nft-expiration-right {
		border-bottom-right-radius: 20px;
		border-top-right-radius: 20px;
		background-color: rgba(68, 133, 230, 0.9);
	}
}
