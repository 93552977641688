.flowty-modal {
	.modal-content {
		background-color: $grey-dark;
	}

	.modal-footer {
		border-top: 0;
	}

	.modal-header {
		border-bottom: 0;
	}
}

.modal-external-link {
	color: $primary;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}
