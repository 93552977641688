@import "../Components/NftCard.scss";
@import "../Components/LoanCard.scss";
@import "../Components/LayoutLoader.scss";
@import "./UpToDateCard.scss";
@import "./TakeMeToMarketplace.scss";

$block-top-margin-big: 160px;

.how-it-works-block,
.latest-listings-block,
.stay-up-to-date-block,
.take-me-to-the-marketplace-block,
.contact-us-block,
.footer-block {
	margin-top: $block-top-margin-big;
}

.line-separator {
	height: 2px;
	width: 100%;
}
