@import "../Components/NftExpiration.scss";

.nft-image-card {
	position: relative;

	.nft-image {
		object-fit: cover;
		width: 75%;
		height: 75%;
		border-radius: 20px;
	}

	border-radius: 20px;
}

.nft-image-alert {
	position: absolute;
	top: 0px;
	left: 0px;
	// padding-left: 15px;
	// padding-right: 15px;

	.countdown-content {
		padding-top: 5px;
		padding-bottom: 5px;
		// border-radius: 10px;
		background: rgba(68, 133, 230, 0.9);
	}
}

.nft-image-user {
	position: absolute;
	top: 0px;
	left: 0px;

	.current-user-listing {
		padding-top: 5px;
		padding-bottom: 5px;
		background: rgba(68, 133, 230, 0.9);
	}
}

.nft-image-private {
	position: absolute;
	top: 0px;
	left: 0px;

	.private-listing-renter {
		padding-top: 5px;
		padding-bottom: 5px;
		background: $primary;
	}

	.private-listing-not-renter {
		padding-top: 5px;
		padding-bottom: 5px;
		background: white;
	}
}

.offers-container {
	overflow: hidden;
	overflow-y: scroll;
	max-height: 190px;

	.nft-offer-row {
		margin-top: 10px;
		margin-bottom: 10px;

		.avatar-container {
			width: 36px;
			height: 36px;

			object-fit: cover;
			border-radius: 18px;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}
}

.nft-info-card {
	padding: 20px;

	.nft-info-card-container {
		border-radius: 10px;
		padding: 20px;
	}

	.nft-info-separator {
		width: 100%;
		height: 1px;
	}
}

.nft-price {
	p {
		font-size: 30px;
	}

	&.small {
		p {
			font-size: 14px;
		}
	}
}

.nft-state-placeholder {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(68, 76, 91, 0.8);
	z-index: 9;
	border-radius: 20px;
}

.name-title {
	width: 60%;
	display: flex;
	justify-content: flex-start;
}

.collection-card {
	border-color: #ffffff80 !important;
}
