// Kept this file as we use these variables in multiple spots, while we are at it I'll add some extra styles needed here
$primary: #04e5a3;
$black: #031021;
$white: #f2f2f3;
$grey-light: #9da1a8;
$grey-lines: #444c5b;
$grey-dark: #1c2737;

$grey: #949699;

$body-bg: $black;
$body-color: $white;

$border-radius: 20px;

// Nav Dropdown colors
$nav-dropdown-bkg: #333e4c;
$nav-dropdown-hover: rgba(243, 245, 249, 0.3);
$nav-dropdown-select: rgba(68, 133, 230, 0.9);

// Dropdown colors
$dropdown-bkg: #1c2737;
$dropdown-hover: rgba(243, 245, 249, 0.3);
$dropdown-select: rgba(68, 133, 230, 0.9);

$selected-nft-border: #72d2ab;

body {
	background-color: #04070b;
	color: #f2f2f3;
	height: 100%;
}

.container {
	margin-left: auto;
	margin-right: auto;
}
