.user-avatar {
	overflow: hidden;
	display: inline-block;

	top: -110px;

	width: 180px;
	height: 180px;

	border: 5px solid #ffffff;
	box-sizing: border-box;
	border-radius: 90px;

	object-fit: cover;

	.avatar-image {
		width: 100%;
		height: 100%;
	}
}

.user-profile-sumary {
	background: linear-gradient(
		104.08deg,
		rgba(255, 255, 255, 0.2) 1.78%,
		rgba(255, 255, 255, 0.05) 67.74%
	);

	border-radius: 10px;

	.sumary-separator {
		width: 1px;
		height: 36px;
		background-color: $white;
		margin-left: 20px;
		margin-right: 20px;
	}
}

.user-profile-tabnav {
	.nav-tabs {
		border-bottom: 0;

		.nav-item {
		}

		.nav-link {
			transition: none;
			// transition-property: color, background-color, border-color;
			// transition-duration: 0.15s, 0.15s, 0.15s;
			// transition-timing-function: ease-in-out, ease-in-out, ease-in-out;
			// transition-delay: 0s, 0s, 0s;

			color: $grey-light;

			border: 0;

			&:focus,
			&:hover {
				border: 0;
			}

			&.active {
				background-color: transparent;
				border: 0;
				border-bottom: 3px solid $primary;
				color: $white;
			}
		}
	}
}

.nft-label {
	font-family: MontserratBold, sans-serif;
	font-style: normal;
	font-weight: bold;

	display: inline-block;
	padding: 4px;
	border-radius: 3px;

	font-weight: bold;
	font-size: 12px;
	line-height: 15px;

	letter-spacing: 0.05em;
	color: $white;
	text-transform: uppercase;

	&.listed {
		background-color: #e7b046e5;
	}

	&.funded {
		background-color: #4485e5;
	}

	&.repaid {
		background-color: $primary;
	}

	&.delisted {
		background-color: $grey-dark;
	}

	&.settled {
		background-color: $primary;
	}
}
