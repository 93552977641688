@media (min-width: 768px) {
	.btn-connected-wallet {
		.dropdown-menu {
			right: 0;
			left: auto;
		}
	}
}

.btn-connected-wallet {
	height: 50px;

	.open-icon {
		display: none;
	}

	&.show {
		.open-icon {
			display: inline;
		}

		.close-icon {
			display: none;
		}
	}

	.dropdown-toggle::after {
		content: none;
	}

	.dropdown-toggle {
		padding: 8px;
	}

	.dropdown-menu {
		background-color: #222d38;
		padding: 1.75rem;
		border-radius: 10px;
		margin-top: 0.5em;
		min-width: 380px;
		z-index: 20;
	}

	.avatar-container {
		object-fit: cover;
		border-radius: 5px;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
		}
	}
}

.new-avatar-container {
	object-fit: cover;
	border-radius: 5px;
	overflow: hidden;

	img {
		width: 100%;
		height: 100%;
	}
}

.wallet-balance-dropdown {
	border-radius: 25px;
	margin-bottom: 10px;

	.stable-coin-icons {
		position: relative;
	}

	.dropdown-toggle::after {
		content: none;
	}

	.dropdown-toggle {
		//padding: 8px;
	}

	.dropdown-menu {
		margin-top: 10px;
	}
}

.wallet-balance-table {
	width: 215px !important;
}

.navbar-expand .navbar-nav .dropdown-menu {
	top: 55px;
	width: 100%;
	color: black;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
	position: absolute;
}

// Dropdown item
.dropdown-item {
	color: $white;
	border-radius: 20px;
	height: 40px;
	display: flex;
	align-items: center;
	padding: 10px;

	&.active {
		background-color: $nav-dropdown-select;
	}
}

.dropdown-item:hover,
.dropdown-item:focus {
	background-color: $nav-dropdown-hover;
	color: $white;
}

.navbar-toggler {
	border: 0;

	&:active,
	&:focus {
		border: 0;
		box-shadow: none;
	}

	.icon-bar {
		margin-top: 4px;
		margin-bottom: 4px;
		width: 22px;
		height: 2px;
		background-color: $white;
		transition: all 0.2s;
	}

	.top-bar {
		transform: rotate(45deg);
		transform-origin: 10% 10%;
	}

	.middle-bar {
		opacity: 0;
	}

	.bottom-bar {
		transform: rotate(-45deg);
		transform-origin: 10% 90%;
	}

	&.collapsed {
		.top-bar {
			transform: rotate(0);
		}

		.middle-bar {
			opacity: 1;
		}

		.bottom-bar {
			transform: rotate(0);
		}
	}
}
