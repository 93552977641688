.up-to-date-card {
	height: 217px;
	background: linear-gradient(
		104.08deg,
		rgba(255, 255, 255, 0.2) 1.78%,
		rgba(255, 255, 255, 0.05) 67.74%
	);

	box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.05);
	border-radius: 30px;

	// .form-control-subscribe,
	.form-container,
	#formSubscribeEmail {
		margin-right: -20px;
	}

	.btn-subscribe {
		position: relative;
	}
}
