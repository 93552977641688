@import "./CollectionIListing.scss";
@import "./CollectionItem.scss";
@import "./ListLoan.scss";

.border-gradient {
	border-style: solid;
	border-width: 0px 0px 0.5px 0px;
	border-image: linear-gradient(
			to right,
			rgba(0, 0, 0, 0),
			#4c5b69,
			rgba(0, 0, 0, 0)
		)
		1;
}

.horizontal-border-gradient {
	border-style: solid;
	border-width: 0px 0px 0px 0.5px;
	border-image: linear-gradient(
			to top,
			rgba(0, 0, 0, 0),
			#4c5b69,
			rgba(0, 0, 0, 0)
		)
		1;
}
