.metadata-grid {
	display: flex;
}

.metadata-container {
	border: 3px solid #43484c;
	background-color: #43484c;
	border-radius: 20px;
	min-height: 75px;
	min-width: 100px;
	margin: 5px;
}

.metadata-container p {
	vertical-align: top;
	margin: 0;
}

.large {
	font-size: 20px;
	line-height: 1;
	width: 100%;
}

.metadata-table {
	z-index: 0;
	background: $grey-dark;
}
