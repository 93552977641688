div.LandingPageScroll::-webkit-scrollbar {
	height: 15px;
	width: 16px;
}

div.LandingPageScroll::-webkit-scrollbar-thumb {
	background-color: rgba(186, 186, 192, 0.2);
	border-radius: 16px;
}

div.LandingPageScroll:hover::-webkit-scrollbar-thumb:hover {
	background-color: #babac036;
	border-radius: 16px;
	border: 5px solid rgba(151, 151, 151, 0.308);
}

div.LandingPageScroll::-webkit-scrollbar-track:hover {
	background-color: rgba(255, 255, 255, 0.151);
	border-radius: 16px;
}
