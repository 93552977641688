.user-profile-settings {
	.tab-pane {
		// padding-left: 30px;
	}

	.settings-bkg {
		background: rgba(255, 255, 255, 0.1);
		border-radius: 10px;
		padding: 20px 20px;
	}

	.nav {
		.nav-item {
			margin-top: 5px;
			margin-bottom: 5px;

			.nav-link {
				padding: 4px 10px;
				color: $grey-light;

				&.active {
					a {
						color: $primary;
					}
					background: $grey-dark;
					/* Neu button */

					box-shadow: -2px -1px 4px rgba(255, 255, 255, 0.1),
						6px 3px 10px rgba(3, 16, 33, 0.5);
					border-radius: 23px;
				}
			}
		}
	}
}

.notification-form-group {
	background: rgba(243, 245, 249, 0.05);
	border-radius: 10px;
	padding: 10px 30px;
}
