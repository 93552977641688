.mobile-loan-list-container {
	position: fixed;
	z-index: 998;
	bottom: 0px;
	left: 0;
	// left: 50%;
	// transform: translateX(-50%);
	width: 100%;
	height: 100px;
	background: linear-gradient(
		180deg,
		rgba(3, 16, 33, 0) 0%,
		rgba(3, 16, 33, 0.78) 30.67%,
		#031021 100%
	);
}

.loan-listing-modal {
	.right-arrow-icon {
		position: relative;
		right: -20%;
	}

	.left-arrow-icon {
		position: relative;
		left: -20%;
	}
}

.select-collateral {
	max-height: 500px;
	overflow: hidden;
	overflow-y: auto;
	border-bottom: 1px solid white;
}
//TODO move this to its own file
.swap-listing {
	max-height: 700px;
	overflow: hidden;
	overflow-y: auto;
}
