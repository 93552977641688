.loan-card {
	&:hover {
		border: 2px solid white;
	}

	&.selected {
		background-color: $grey-light;

		border: solid $grey-light;
		transition: border-width 0.05s linear;
	}

	&.selected:hover {
		border: 2px solid white;
	}

	&.unselected {
		background-color: $black;
	}

	&.disabled {
		cursor: no-drop;
	}
}

.loan-card-parent {
	display: block;
	position: relative;
}

.loan-card-overlay {
	width: 100%;
	height: 100%;
	border-radius: 18px;
	overflow: hidden;
	position: absolute;
	background: $grey-dark;
	z-index: 100;
	opacity: 50%;

	pointer-events: none;
	cursor: no-drop;
}

.loan-card-text {
	color: white;
	text-shadow: -0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000,
		0.5px 0.5px 0 #000;
}

.disabled-collateral {
	cursor: no-drop;
	position: absolute;
	background-color: $grey-dark;

	z-index: 999;
}
